import 'piral/polyfills';
import { renderInstance } from 'piral';
import { layout, errors } from './layout';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

var config = {
  apiKey: "AIzaSyA0JMcaZ60ehuzedtfD5UiPVmPif_T6w-w",
  authDomain: "brightspots.firebaseapp.com",
  databaseURL: "https://brightspots.firebaseio.com",
  projectId: "brightspots",
  storageBucket: "brightspots.appspot.com",
  messagingSenderId: "1015498659808",
  appId: "1:1015498659808:web:9066ff8483324589c23d85",
  measurementId: "G-ETF5BJ5E5Q"
};

firebase.initializeApp(config);
firebase.analytics();

// change to your feed URL here (either using feed.piral.io or your own service)
const feedUrl = 'https://feed.piral.io/api/v1/pilet/brightspots';


renderInstance({
  layout,
  errors,
  requestPilets() {
    return fetch(feedUrl)
      .then(res => res.json())
      .then(res => res.items);
  },
});
